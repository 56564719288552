import React , {Component}  from 'react';
import './Me.css';
import base64 from 'react-native-base64';

class Menu extends Component {
    constructor(){
        super();
        this.state = {loaded: false};
    }
    handleEmail(e){
        //var info = "ZnJhbmsua291bmVsaXNAZ21haWwuY29t";
        var info = "aGVsbG9AZnJhbmtrb3VuZWxpcy5jb20=";
        if(document.getElementById("email").innerHTML != base64.decode(info) ){
            e.preventDefault();
            document.getElementById("email").innerHTML = base64.decode(info);
            document.getElementById("email").setAttribute("href", "mailto:"+base64.decode(info)); 
        }
          
    }
    handleMobile(e){
        var info = "MDQzNCA0OTUgNTY1";
        if(document.getElementById("mobile").innerHTML != base64.decode(info)){
            e.preventDefault();
            document.getElementById("mobile").innerHTML = base64.decode(info);
            document.getElementById("mobile").setAttribute("href", "tel:"+base64.decode(info));   
        }
    }
    render() 
    {
        return(
            <div className="me-section">
                <div id="Me"></div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
        					<div className="col-md-4 rm-auto order-md-2">
                            <div className="circle">
                                <div className={this.state.loaded ? 'colouredBorder' : ''}>
                                    <div className="myImage">
                                        <img
                                            alt={"Me"}
                                            height={"auto"}
                                            src="assets/graduate-me-2.jpg"
                                            width={"100%"} 
                                            loading="lazy"
                                            onLoad={() => this.setState({loaded: true})}
                                        />
                                    </div>
                                </div>
									
                                </div>
                            </div>
                            <div className="col-md-8 order-md-1">
                            <h5>Who am I?</h5>
                            <p>
                            Friendly and enthusiastic, I bring energy to website development. 
                            While on a quest for purpose, I found web development to be my North Star. 
                            Got a bug on your website? Need a process automated? I'm your man.
                                <br /> <br />
                                Email: <a href id="email" onClick={this.handleEmail.bind(this)}> Click to reveal </a> <br />
                                Mobile: <a href id="mobile" onClick={this.handleMobile.bind(this)}>Click to reveal</a>   <br />
                                Location:  <span id="location"> Sydney, NSW </span><br />
                                LinkedIn: <a href="https://www.linkedin.com/in/frank-kounelis/" target="_blank"> View my profile</a> <br />
                            </p>
                            <h5 id="myProfile">My Profile</h5>
                            <p>
                                Proven organisational, time-management skills. Solid practical computer experience developed through full-time employment in IT Support and Web Developement.
                                I am enthusiastic about furthering my skills and knowledge in the IT field. My ability to learn and adapt
                                to new tasks quickly and efficiently, means I can get the job done quickly.
                            </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
  }
}

export default Menu;
