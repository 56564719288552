import React , {Component}  from 'react';
import './Sublime.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Sublime/home-page.jpg" alt="Home Page">
        <p>Home Page</p>
      </div>
      <div data-src="/assets/Sublime/welcome-home.jpg" alt="Main Information">
        <p>Main points stated here</p>
      </div>
      <div data-src="/assets/Sublime/events.jpg" alt="Events Section">
        <p>Upcoming events for the cafe</p>
      </div>
      <div data-src="/assets/Sublime/gallery.jpg" alt="Gallery">
        <p>Gallery to showcase events held at the cafe</p>
      </div>
      <div data-src="/assets/Sublime/contact.jpg" alt="Contact Section">
        <p>Contact Page</p>
      </div>
    </AwesomeSlider>
  );

class Sublime extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

     
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="sublime">
            <div id="Sublime"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title text-white">Sublime Point Cafe</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-white">
                                Sublime Point Cafe is a website I have developed myself. I used WordPress as the framework to build the website and the client was happy with the end result. 
                                I used the knowlege and skills I garnered from working at Tick Yes and Interactive Investor to help build this website. 
                                <br /><br /> 
                                Visit <a className="weblink" href="https://www.sublimepoint.com.au/" target="_blank" rel="noopener noreferrer">Sublime Point Cafe</a>
                                <br /><br /> 
                                Below are some images of the website.
                                </p>
                            </div>
                        </div>
                        <div className="row Sublime-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default Sublime;
