import React , {Component}  from 'react';
import './Sprout.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Sprout/1.jpg" alt="Main Page">
        <p>Main Page Component View</p>
      </div>
      <div data-src="/assets/Sprout/2.jpg" alt="Technical Writing Component">
        <p>Technical Writing Component View</p>
      </div>
      <div data-src="/assets/Sprout/3.jpg" alt="Facts Component">
        <p>Facts Component View</p>
      </div>
      <div data-src="/assets/Sprout/4.jpg" alt="Contact Component">
        <p>Contact Component View</p>
      </div>
    </AwesomeSlider>
  );

class Sprout extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

      componentDidMount(){
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }
      
      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: parseFloat(scrolled).toFixed(2),
        })

        if (this.state.theposition == 0.40 || this.state.theposition == 0.41) { 
            this.setState({
                show: true
            })
        } 
        else if(this.state.theposition > 0.41 ){
            this.setState({
                show: true
            })
        }
      }
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="sprout">
            <div id="Sprout"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Sprout Scientific</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-white">
                                I previously had the position of Full Stack Web Developer at Sprout Scientific, where I had built and updated most of my websites using WordPress. 
                                The framework I developed for the Sprout Scientific website used React.js and Node.js.
                                <br /><br /> 
                                Visit <a className="weblink" href="https://www.sproutscientific.com/" target="_blank" rel="noopener noreferrer">Sprout Scientific</a>
                                <br /> <br />
                                Below are some images of the website and components
                                </p>
                            </div>
                        </div>
                        <div className="row sprout-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default Sprout;
