import React , {Component}  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sections.css';

class Sections extends Component {

render() 
{
    return(
        <div className="center-sections">
            <div id="My-Projects"></div>
            <div className="center-block">
                <h1 id="section-heading">My Projects</h1>
            </div>
            <div className="section-listing">
                <div className="center-block">
                    <a className="section-card" href="#ClearView">
                    <div className="card-listing">
    					<div className="card-img-top">
                        	<img
                           		alt={"ClearView"}
                            	height={"auto"}
                            	src="assets/Sections/ClearView.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>ClearView Glass Solutions</h3>
                            <p className="card-text">
                                ClearView Glass Solutions is my second Freelance website I have developed.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Sublime">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Sublime"}
                            	height={"auto"}
                            	src="assets/Sections/sublime.jpg"
                            	width={"100%"} 
                        	/>
						</div>	
                        <div className="card-body section-crd-bdy ">
                            <h3>Sublime</h3>
                            <p className="card-text">
                                Sublime Point Cafe is my first <b>Freelance</b> website I have developed.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Metromix">
                    <div className="card-listing">
                   		<div className="card-img-top">
                        	<img
                           		alt={"Metromix"}
                            	height={"auto"}
                            	src="assets/Sections/metromix.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Metromix</h3>
                            <p className="card-text">
                                The "New" Metromix Website was the last project I worked on before leaving Tick Yes.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Myasana">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Myasana"}
                            	height={"auto"}
                            	src="assets/Sections/myasana.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Myasana</h3>
                            <p className="card-text">
                                The Myasana website was my favourite project to work on for Interactive Inverstor, as I developed the website from start to finish.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Metromix-Sales-Hub">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Metromix Sales Hub"}
                            	height={"auto"}
                            	src="assets/Sections/metromix-sh.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Metromix Sales-Hub</h3>
                            <p className="card-text">
                                The Metromix Sales-Hub was my first project developing a website for Tick Yes one of their clients.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Interclinical">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Interclinical"}
                            	height={"auto"}
                            	src="assets/Sections/interclinical.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Interclinical Labratories</h3>
                            <p className="card-text">
                                The Interclinical Labratories website was a project I helped develop on for Interactive Investor and one of their clients.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Mega">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Mega"}
                            	height={"auto"}
                            	src="assets/Sections/mega.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Mums Exercise Goup Australia</h3>
                            <p className="card-text">
                                The MEGA project was my first major job developing a website for a client. I was the backend developer for the team.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Sprout">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Sprout"}
                            	height={"auto"}
                            	src="assets/Sections/sprout.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Sprout Scientific</h3>
                            <p className="card-text">
                                My first web developemnt position is at Sprout Scientific, the first project for me was to build 
                                a React Website.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#Standby">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"Standby"}
                            	height={"auto"}
                            	src="assets/Sections/standby.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>Standby Property Management</h3>
                            <p className="card-text">
                                Standby was the first website I built using WordPress, customising themes and plugins for the client's needs.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="center-block">
                    <a className="section-card" href="#TMPhysio">
                    <div className="card-listing">
                    	<div className="card-img-top">
                        	<img
                           		alt={"TMPysio"}
                            	height={"auto"}
                            	src="assets/Sections/tmphysio.jpg"
                            	width={"100%"} 
                        	/>
						</div>
                        <div className="card-body section-crd-bdy ">
                            <h3>TMPhysio Canberra</h3>
                            <p className="card-text">
                                Standby is the second website I customised and updated using wordpress.
                            </p>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

}

export default Sections;

