import React , {Component}  from 'react';
import './Metromix-SH.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Metromix-SH/form.jpg" alt="Form">
        <p>The form which truck drivers use</p>
      </div>
      <div data-src="/assets/Metromix-SH/form-entries.jpg" alt="Form Entries">
        <p>The form entries admins use to follow up leads</p>
      </div>
      <div data-src="/assets/Metromix-SH/form-entry-graphs.jpg" alt="Form Entry Graphs">
        <p>Graphs for visual representation</p>
      </div>
    </AwesomeSlider>
  );

class MetromixSH extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

     
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="metromix-sh">
            <div id="Metromix-Sales-Hub"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Metromix Sales-Hub</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                The Metromix Sales-Hub was my first project undertaken at Tick Yes. The purpose of the project was to allow Tick Yes's Client, Metromix, to collect leads from their truck drivers.
                                If a truck driver came across a construction site that required a concrete job to be done, they could use the Sales Hub to upload all the information of the site. The Sales team would then use the uploaded
                                information to contact the site manager to maybe secure a concrete job at the construction site. Wordpress was used to build this form, customised to suite the clients needs.
                                <br /><br /> 
                                Below are some images of the form and administration area
                                </p>
                            </div>
                        </div>
                        <div className="row metromixsh-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default MetromixSH;
