import React , {Component}  from 'react';
import './Mega.css';
import Fade from 'react-reveal/Fade';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
  <AwesomeSlider cssModule={styles}>
    <div data-src="/assets/Mega/Main-Page.jpg" alt="Main Page">
      <p>Main Page</p>
    </div>
    <div data-src="/assets/Mega/Classes-Page.jpg" alt="Classes Page">
      <p>Classes Page</p>
    </div>
    <div data-src="/assets/Mega/My-Classes-Page.jpg" alt="My Classes Page">
      <p>My Classes Page</p>
    </div>
    <div data-src="/assets/Mega/Profile-Page.jpg" alt="Profile Page">
      <p>Profile Page</p>
    </div>
    <div data-src="/assets/Mega/Admin-Dashboard-Page.jpg" alt="Admin Dashboard Page">
      <p>Admin Dashboard Page</p>
    </div>
  </AwesomeSlider>
);



class Mega extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show: false,
        };
      }

      componentDidMount(){
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }


    onChangeSelect = event => this.setState({ [event.target.name]: event.target.value });
      
      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: parseFloat(scrolled).toFixed(2),
        })

        if (this.state.theposition == 0.22 || this.state.theposition == 0.23) { 
            this.setState({
                show: true
            })
        } 
        else if(this.state.theposition > 0.23){
            this.setState({
                show: true
            })
        }
    }

    state = {
    galleryItems: [1, 2, 3].map((i) => <h2 key={i}>{i}</h2>),
  }
 
  responsive = {
    0: { items: 1 },
    1024: { items: 2 },
  }
 
  onSlideChange(e) {
    console.debug('Item`s position during a change: ', e.item)
    console.debug('Slide`s position during a change: ', e.slide)
  }
 
  onSlideChanged(e) {
    console.debug('Item`s position after changes: ', e.item)
    console.debug('Slide`s position after changes: ', e.slide)
  }
      
    render() 
    {
        //  <Fade bottom big cascade when={this.state.show}></Fade>
        return(
            <div id="megaSection" className="card">
                <div id="Mega"></div>
                <div className="card-body">
                    <h5 className="card-title">Mega Project</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    During my studies at Wollongong University, I was part of a team that built a 
                                    class booking system for our client, MEGA. My position for the team was Backend Developer
                                    with the responsiblity of building the PHP and MYSQL queries for the website we developed. 
                                    The backend results were then displayed on the frontend using AJAX calls, returning specific 
                                    information based on the response sent from the backend. This project helped me improve my 
                                    knowledge and skills for both MYSQL and PHP. <br /> <br />

                                    Visit <a href="https://mega.fk-projects.dynu.net/" target="_blank" rel="noopener noreferrer">MEGA</a>
                                    <br /> <br />

                                    Below are some images demonstrating the final design of the website.
                                </p>
                            </div>
                        </div>
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: "center" }}>
                        { Slider }
                        </div>
                    </div>
                </div>
            </div>
        )
  }
}

export default Mega;
