import React , {Component}  from 'react';
import './Metromix.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Metromix/Home-Page-Landing.jpg" alt="Home Landing Page">
        <p>Home Landing Page</p>
      </div>
      <div data-src="/assets/Metromix/Projects-Home.jpg" alt="Projects Section">
        <p>Projects Section</p>
      </div>
      <div data-src="/assets/Metromix/Able-Home.jpg" alt="Able Home Page">
        <p>Able Home Page</p>
      </div>
      <div data-src="/assets/Metromix/Able-Products.jpg" alt="Able Products">
        <p>Able Products</p>
      </div>
    </AwesomeSlider>
  );

class Metromix extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

     
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="metromix">
            <div id="Metromix"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Metromix</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                The "New" Metromix Website was the last project I worked on before leaving Tick Yes. The project was to refresh the look of Metromix as their current website was outdated.
                                The project was to help make the company feel more appealing to new audiances.
                                <br /><br /> 
                                Visit <a href="https://www.metromix.com.au/" target="_blank" rel="noopener noreferrer">Metromix</a>
                                <br /><br /> 
                                Below are some images of the new website.
                                </p>
                            </div>
                        </div>
                        <div className="row metromix-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default Metromix;
