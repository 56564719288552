import React from 'react';
import ScrollTop from "react-scrolltop-button";
import 'pure-react-carousel/dist/react-carousel.es.css';

import Menu from '../Sub Components/Menu/NavBar';
import Sections from '../Sub Components/Sections/Sections';
import Me from '../Sub Components/Me/Me';
import Employment from '../Sub Components/Employment/Employment';
import ClearView from '../Sub Components/ClearView/ClearView';
import Sublime from '../Sub Components/Sublime/Sublime';
import Metromix from '../Sub Components/Metromix/Metromix';
import Myasana from '../Sub Components/Myasana/Myasana';
import MetromixSH from '../Sub Components/Metromix-SH/Metromix-SH';
import Interclinical from '../Sub Components/Interclinical/Interclinical';
import Mega from '../Sub Components/Mega/Mega';
import Sprout from '../Sub Components/Sprout/Sprout';
import Standby from '../Sub Components/Standby/Standby';
import Tmphysio from '../Sub Components/tmphysio/Tmphysio';
import Footer from '../Footer/Footer';

function App() {
  return (
    <div className="App">
        <Menu />
        <Me />
        <Sections />
        <Employment />
        <ClearView />
        <Sublime />
        <Metromix />
        <Myasana />
        <MetromixSH />
        <Interclinical />
        <Mega />
        <Sprout />
        <Standby />
        <Tmphysio />
        <Footer />

        <ScrollTop
          text="To Top"
          distance={200}
          breakpoint={768}
          className="scroll-your-role"
          speed={500}
          target={0}
        />
    </div>
  );
}

export default App;
