import React , {Component}  from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './NavBar.css';
import onClickOutside from 'react-onclickoutside'



class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideIn: "",
            clicked: false,
            collapse: "collapse",
            show: "",
            showSub: "",
            subClicked: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleSubClick = this.handleSubClick.bind(this);
        
      }

      componentDidMount(){
        window.addEventListener('scroll', this.listenToScroll);
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
      }
      
      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: parseFloat(scrolled).toFixed(3),
        })

        if (this.state.theposition > 0.005) { 
            this.setState({
                slideIn: "navbar_fixed"
            })
        } 
        else if(this.state.theposition < 0.005){
            this.setState({
                slideIn: ""
            })
        }
      }

    handleSubClick = (e) => {
        if(this.state.subClicked == true){
            this.setState({
                showSub: ""
            });
            this.setState({
                subClicked: false
            });
        }
        else{
            this.setState({
                showSub: "show"
            });    
            this.setState({
                subClicked: true
            });           
        }
    }

    handleClick = (e) => {        
        if(this.state.clicked == true){
            this.setState({
                clicked: false
            });

            this.setState({
                collapse: ""
            });

            this.setState({
                show: ""
            });
            this.setState({
                subClicked: false
            });
            this.setState({
                showSub: ""
            });
        }
        else{
            this.setState({
                clicked: true
            });

            this.setState({
                collapse: "collapse"
            });

            this.setState({
                show: "show"
            });           
        }
    }

    handleClickOutside = () => {
        this.setState({
            clicked: false
        });
    
        this.setState({
            collapse: ""
        });
    
        this.setState({
            show: ""
        });
        this.setState({
            subClicked: false
        });
        this.setState({
            showSub: ""
        });
    }

    render() 
    {
        return(
            <header className={`header_area ${this.state.slideIn}`}>
                <div className="main_menu">
                    <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container box_1620">

                    <a className="navbar-brand logo_h" href="/">Frank Kounelis</a>

                    <button className="navbar-toggler" type="button" data-toggle={this.state.collapse} data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={this.state.clicked} aria-label="Toggle navigation" onClick={this.handleClick}>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                    <div className={`collapse navbar-collapse offset ${this.state.show}`} id="navbarSupportedContent">
                    <ul className="nav navbar-nav menu_nav ml-auto">
                        <li className="nav-item active"><a className="nav-link" href="#Me" onClick={this.handleClick}>Who am I?</a></li>
                        <li className="nav-item"><a className="nav-link" href="#Employment" onClick={this.handleClick}>Employment History</a></li>
                        <li className={`nav-item submenu dropdown ${this.state.showSub}`}>
                            <a href="#My-Projects" className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded={this.state.subClicked} onClick={this.handleSubClick}>My Projects</a>
                            <ul className={`dropdown-menu ${this.state.showSub}`}>
                                <li className="nav-item"><a className="nav-link" href="#ClearView" onClick={this.handleClick}>ClearView</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Sublime" onClick={this.handleClick}>Sublime</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Metromix" onClick={this.handleClick}>Metromix</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Myasana" onClick={this.handleClick}>Myasana</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Metromix-Sales-Hub" onClick={this.handleClick}>Sales-Hub</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Interclinical" onClick={this.handleClick}>Interclinical</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Mega" onClick={this.handleClick}>Mega</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Sprout" onClick={this.handleClick}>Sprout</a></li>
                                <li className="nav-item"><a className="nav-link" href="#Standby" onClick={this.handleClick}>Standby</a></li>
                                <li className="nav-item"><a className="nav-link" href="#TMPhysio" onClick={this.handleClick}>TMPhysio</a></li>
                            </ul>
                        </li>
                    </ul>
                    </div>
                    </div>
                    </nav>
                </div>
            </header>
        )
  }
}

export default onClickOutside(Menu);
