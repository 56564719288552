import React , {Component}  from 'react';
import './Employment.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

class Employment extends Component {
    render() 
    {
        return(
            <div className="employment">
                <div id="Employment" ></div>
                <div className="center-block">
                    <h1 id="employment-heading">Employment History</h1>
                </div>
                <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="June 2020 - Current"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    //iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    
                    >
                    <h3 className="vertical-timeline-element-title">Wordpress Web Developer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Hustle Digital - Pyrmont NSW</h4>
                    <p>
                        <ul className="tasks">
                            <li>Building and Maintaining WordPress Websites for Hustle Digital's clients</li>
                            <li>Developed Custom Web Ordering System's for Clints working in the restaurant industry during COVID</li>
                            <li>Developed a Custom Booking System for Concierge Car Wash, Built with React and NodeJS</li>    
                            <li>
                                Development of many iOS and Android apps, built using React Native and NodeJS <br />
                                <ul className='linksForExamples'>
                                    <li>
                                        <a href='https://apps.apple.com/us/app/disport-live-sports-chats/id1613420079' target='_blank'>
                                            Disport
                                        </a>
                                    </li>    
                                </ul>
                            </li>    
                            
                        </ul>
                    </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="September 2019 - December 2019"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    
                    >
                    <h3 className="vertical-timeline-element-title">WordPress Web Developer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Tick Yes/Interactive Investor - Bondi Junction NSW</h4>
                    <p>
                        <ul className="tasks">
                            <li>Built brand new websites for Tick Yes and its Clients using the CMS WordPress</li>
                            <li>Maintained and updated existing websites for Tick Yes</li>
                            <li>Custom coded plugins and templates to suit client needs</li>
                                <ul>
                                    <li>Only show specific form enteries depending on user</li>
                                    <li>Display specific images depending on user</li>
                                </ul>
                            <li>Integrating and customising WordPress Plugins to suit client’s needs</li>
                        </ul>
                    </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Nov 2018 - September 2019"
                    //iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    
                    >
                    <h3 className="vertical-timeline-element-title">Full Stack Developer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Sprout Scientific - Wollonong NSW</h4>
                    <p>
                        <ul className="tasks">
                            <li>Building and Maintaining WordPress Websites for Sprout’s clients</li>
                            <li>Integrating and customising WordPress Plugins to suit client’s needs</li>
                            <li>Front End Development using JavaScript technologies, such as React and Node.js</li>
                            <li>Editing WordPress frameworks and plugins to suit the clients needs</li>
                            <li>Providing the service of building websites for Sprout and its clients</li>
                            <li>Research technologies for Sprout to determine the best course of action</li>
                            <li>Compare current technologies to provide the best service to Sprout’s clients</li>
                            <li>Working in a team environment</li>
                            <li>Describing how to use new features of a website to clients</li>
                            <li>Explaining why certain features were developed to help the client understand how it works</li>
                        </ul>
                    </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="April 2018 - July 2018"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    
                    >
                    <h3 className="vertical-timeline-element-title">Technical Support Officer</h3>
                    <h4 className="vertical-timeline-element-subtitle">Figtree High School - Wollongong NSW</h4>
                    <p>
                        <ul className="tasks">
                            <li>Provided technical assistance to staff and students</li>
                            <li>Helped with the installation of new printers throughout the school</li>
                                <ul>
                                    <li>Printers are in the correct locations</li>
                                    <li>Printers have the correct IP Addresses</li>
                                    <li>Creating address books for scanning capabilities</li>
                                </ul>
                            <li>Transitioned school Moodle website to local server </li>
                                <ul>
                                    <li>Set up new server</li>
                                    <li>Upgraded from Moodle 2.6 to Moodle 3.1</li>
                                </ul>
                            <li>Made visual changes to the official school website</li>
                                <ul>
                                    <li>Colour edits</li>
                                    <li>Image changes</li>
                                    <li>Rearranged text locations</li>
                                </ul>
                        </ul>
                    </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="August 2016 - March 2017"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    
                    >
                    <h3 className="vertical-timeline-element-title"> Delivery Driver</h3>
                    <h4 className="vertical-timeline-element-subtitle">Domino’s - Wollongong NSW</h4>
                    <p>
                        <ul className="tasks">
                            <li>Provided excellent customer service, both face-to-face and over-the-phone </li>
                            <li>Responsible for prompt delivery of orders </li>
                            <li>Adhered to driving policies and procedures</li>
                            <li>Responsible for cash-handling and making transactions</li>
                            <li>Professionally greeted customers in a friendly manner </li>
                            <li>Assisted in-store with placing orders, taking phone calls and performing cleaning duties</li>
                        </ul>
                    </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Feburary 2015 - Janurary 2016"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    
                    >
                    <h3 className="vertical-timeline-element-title">IT Support Officer</h3>
                    <h4 className="vertical-timeline-element-subtitle">St James Lutheran College - Hervey Bay QLD</h4>
                    <p>
                        <ul className="tasks">
                            <li>Provided technical assistance to staff and students</li>
                            <li>Experience working with Windows and Mac</li>
                            <li>Walked customers through step-by-step processess</li>
                            <li>Performed upgrades and installed updates on iPads and laptops</li>
                            <li>Assisted customers with troubleshooting problems  </li>
                            <li>Repaired computer equipment and dealt with software issues</li>
                        </ul>
                    </p>
                    </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        )
  }
}

export default Employment;
