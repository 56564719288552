import React , {Component}  from 'react';
import './Standby.css';
//import LightSpeed from 'react-reveal/LightSpeed';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Standby/1.jpg" alt="Home Page">
        <p>Standby Home Landing Page</p>
      </div>
      <div data-src="/assets/Standby/2.jpg" alt="Service Section">
        <p>Standby Services Section</p>
      </div>
      <div data-src="/assets/Standby/5.jpg" alt="Facilities Section">
        <p>Facilities & Open Space Management Section</p>
      </div>
      <div data-src="/assets/Standby/3.jpg" alt="News Section">
        <p>Standby News Section Section</p>
      </div>
      <div data-src="/assets/Standby/6.jpg" alt="Work Sections">
        <p>Work with Standby Section</p>
      </div>
      <div data-src="/assets/Standby/4.jpg" alt="Contact Section">
        <p>Standby Contact Section</p>
      </div>
    </AwesomeSlider>
  );

class Standby extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

      componentDidMount(){
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }
      
      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: parseFloat(scrolled).toFixed(2),
        })

        if (this.state.theposition == 0.70 || this.state.theposition == 0.71) { 
            this.setState({
                show: true
            })
        } 
        else if(this.state.theposition > 0.71 ){
            this.setState({
                show: true
            })
        }
      }
    render() 
    {
	//<LightSpeed right opposite cascade when={this.state.show}></LightSpeed>
        return(
            <div className="standby-section">
                <div id="Standby"></div>
                    <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Standby Property Management</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        Standby was my first project building a wordpress website from scratch. The clients were very happy with the end result. 
                                        Using wordpress meant it allowed me to learn how to modify templates and find and use plugins to suit the clients needs and deliver on client expectations.
                                    <br /><br />
                                    Visit <a href="https://www.standbypm.com.au/" target="_blank" rel="noopener noreferrer">Standby Property Management</a>
                                    <br /> <br />
                                    Below are some images of the website
                                    </p>
                                </div>
                            </div>
                        <div className="row standby-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
  }
}

export default Standby;
