import React , {Component}  from 'react';
import './Tmphysio.css';
//import Rotate from 'react-reveal/Rotate';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/TMPhysio/4.jpg" alt="Article Slider">
        <p>Article Slider on Home Page</p>
      </div>
      <div data-src="/assets/TMPhysio/1.jpg" alt="Article Page">
        <p>Article Page</p>
      </div>
      <div data-src="/assets/TMPhysio/2.jpg" alt="Newsletter Page">
        <p>Newsletter Page</p>
      </div>
      <div data-src="/assets/TMPhysio/3.jpg" alt="Google Map Addon">
        <p>Google Map Addon</p>
      </div>
      <div data-src="/assets/TMPhysio/5.jpg" alt="Facebook Plugin">
        <p>Facebooks Posts Plugin Addon</p>
      </div>
    </AwesomeSlider>
  );

class Tmphysio extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

      componentDidMount(){
        window.addEventListener('scroll', this.listenToScroll)
      }
      
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
      }
      
      listenToScroll = () => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: parseFloat(scrolled).toFixed(2),
        })

        if (this.state.theposition == 0.85 || this.state.theposition == 0.86) { 
            this.setState({
                show: true
            })
        } 
        else if(this.state.theposition > 0.86){
            this.setState({
                show: true
            })
        }
      }
    render() 
    {
	//<Rotate top left opposite when={this.state.show}> </Rotate>
        return(
            
                <div className="tmphysio-section">
                <div id="TMPhysio"></div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">TMPhysio Canberra</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-white">
                                        TMPhysio's website required two new pages, one to display 
                                        articles and one to allow users to download monthly newsletters. Modifying how posts were displayed on the website was achieved 
                                        through changes within the template, allowing the client to create posts with different categories to be displayed on different pages.
                                    <br /><br />
                                    Visit <a className="weblink" href="https://www.tmphysio.com.au/" target="_blank" rel="noopener noreferrer">TMPhysio</a>
                                    <br /> <br />
                                        Some other additions and fixes to the website were: <br />
                                    </p>
                                        <ul className="text-white">
                                                <li>Map locations for contact page</li>
                                                <li>Listing recent articles on the home page </li>
                                                <li>Image resizing to suit larger screens</li>
                                                <li>SEO for the website</li>
                                        </ul>
                                    <p className="text-white">
                                    <br /><br />
                                        Below are some images of my additions to the website
                                    </p>
                                    </div>
                            </div>
                            <div className="row tmphysio-images">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    { Slider }
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
        )
  }
}

export default Tmphysio;
