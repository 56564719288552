import React , {Component}  from 'react';
import './ClearView.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/ClearView/home-page.jpg" alt="Home Page">
        <p>Home Page</p>
      </div>
      <div data-src="/assets/ClearView/info.jpg" alt="Information on ClearView">
        <p>Information on ClearView</p>
      </div>
      <div data-src="/assets/ClearView/services.jpg" alt="Services Section">
        <p>Services Section</p>
      </div>
      <div data-src="/assets/ClearView/about-us-page.jpg" alt="About Us Page">
        <p>About Us Page</p>
      </div>
      <div data-src="/assets/ClearView/glass-fencing.jpg" alt="Glass Pool Fencing Page">
        <p>Glass Pool Fencing Page</p>
      </div>
      <div data-src="/assets/ClearView/why-glass.jpg" alt="Why Glass Pool Fencing Section">
        <p>Why Glass Pool Fencing Section</p>
      </div>
    </AwesomeSlider>
  );

class ClearView extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

     
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="ClearView">
            <div id="ClearView"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">ClearView Glass Solutions</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                ClearView Glass Solutions is my second Freelance website I have developed. It is a website about Glass Pool Fencing and Balustrading. 
                                I am happy with the end result for my client, the project was to be completed in 2 weeks, I completed development before the requested due date.
                                <br /><br /> 
                                Visit <a href="https://clearviewglasspoolfencing.com.au/" target="_blank" rel="noopener noreferrer">ClearView Glass Solutions</a>
                                <br /><br /> 
                                Below are some images of the new website.
                                </p>
                            </div>
                        </div>
                        <div className="row ClearView-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default ClearView;
