import React , {Component}  from 'react';
import './Interclinical.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Interclinical/Home-Landing-Page.jpg" alt="Home Landing Page">
        <p>Home Landing Page</p>
      </div>
      <div data-src="/assets/Interclinical/Home-Info-Part.jpg" alt="Information on the home page">
        <p>Information on the home page</p>
      </div>
      <div data-src="/assets/Interclinical/Home-Contact.jpg" alt="Signup to newsletter section of home page">
        <p>Signup to newsletter section of home page</p>
      </div>
      <div data-src="/assets/Interclinical/HTMA-Page.jpg" alt="HTMA Page - Explaining what is HTMA">
        <p>HTMA Page - Explaining what is HTMA</p>
      </div>
      <div data-src="/assets/Interclinical/Products-Page.jpg" alt="Product Page">
        <p>Product Page</p>
      </div>
    </AwesomeSlider>
  );

class Interclinical extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

     
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="interclinical">
            <div id="Interclinical"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title text-white">Interclinical Labratories</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-white">
                                The Interclinical Labratories website is a project I worked on for Interactive Inverstor, I continued to finish the website after another developer. There were issues building this website as there were three 
                                stakeholders asking us to make changes from the client. In the end I managed to complete the website where all three stakeholders were able to agree on the design of the website. The website was build using 
                                WordPress, with a template chosen by the previous developer. I found it hard to modify this template to the client's needs and learnt that not all templates are lenient when customising code.
                                This website helped me learn that there are some projects are not straight forward.
                                <br /><br /> 
                                Below are some images of the website.
                                </p>
                            </div>
                        </div>
                        <div className="row metromixsh-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default Interclinical;
