import React , {Component}  from 'react';
import './Myasana.css';
//import Bounce from 'react-reveal/Bounce';
import AwesomeSlider from 'react-awesome-slider';
import styles from 'react-awesome-slider/src/styles.js';

const Slider = (
    <AwesomeSlider cssModule={styles}>
      <div data-src="/assets/Myasana/home-video.jpg" alt="Home Landing Page">
        <p>Home Landing Page - Video Autoplays</p>
      </div>
      <div data-src="/assets/Myasana/teachers-home.jpg" alt="Teachers Section">
        <p>Teachers Section on home page</p>
      </div>
      <div data-src="/assets/Myasana/whatson-home.jpg" alt="What's On Section">
        <p>What's On section accompanied by a small gallery</p>
      </div>
      <div data-src="/assets/Myasana/testimonial-home.jpg" alt="Testimonials">
        <p>Testimnial Slider on home page</p>
      </div>
      <div data-src="/assets/Myasana/gallery.jpg" alt="Gallery">
        <p>Gallery Page with category filter</p>
      </div>
      <div data-src="/assets/Myasana/footer.jpg" alt="Footer">
        <p>Footer with 4 columns</p>
      </div>
    </AwesomeSlider>
  );

class Myasana extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
      }

     
    render() 
    {
 	//<Bounce bottom opposite cascade when={this.state.show}></Bounce>
        return(
            <div className="myasana">
            <div id="Myasana"></div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title text-white">Myasana</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="text-white">
                                The Myasana website was my favourite project to work on for Interactive Investor as I developed the website from start to finish. I also improved my knowledge and skills with moving a website from one hosting server to another,
                                redirecting the domian to another IP. The client also provide feedback in a timely manner, allowing the project to complete faster than expected. I was also able to work with a WordPress plugin that uses an external platform for event booking,
                                and integrating it into WordPress.This project allowed me to put into practice my knowledge and skills of WordPress.
                                <br /><br /> 
                                Visit <a className="weblink" href="https://www.myasana.com.au/" target="_blank" rel="noopener noreferrer">Myasana</a>
                                <br /><br /> 
                                Below are some images of the website.
                                </p>
                            </div>
                        </div>
                        <div className="row Myasana-images">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                { Slider }
                            </div>
                        </div>
                </div>
            </div>
            </div>
        )
  }
}

export default Myasana;
